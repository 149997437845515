import {TimeSheetControlPeriodType} from "../../enums/TimeSheetControlPeriodType";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IEmployeeDto} from "../../dtos/employees/IEmployeeDto";
import {addDays, endOfWeek, startOfDay, startOfWeek, subDays} from "date-fns";
import {IGetDaysResponseDto} from "../../dtos/workEntries/IGetDaysResponseDto";
import {WorkEntryService} from "../../services/workEntryService";
import {AppDependencies} from "../../services/appDependencies";
import {isStringEmptyOrWhitespace} from "../../utility/stringUtils/stringUtils";

export interface IWeek {
    weekStart: Date;
    weekEnd: Date;
}


interface ITimeSheetState {
    week: IWeek;
    selectedDate: Date;

    currentEmployeeId?: string;
    currentEmployee?: IEmployeeDto;

    daysInfo: IGetDaysResponseDto | null;
    daysInfoLoadingState: "noAction" | "pending" | "fulfilled" | "rejected";
    
}

const initialState:ITimeSheetState =  {
    week: {
        weekStart: startOfWeek(new Date(), {weekStartsOn: 1}),
        weekEnd: endOfWeek(new Date(), {weekStartsOn: 1}),
    },
    selectedDate: startOfDay(new Date()),
    daysInfo: null,
    daysInfoLoadingState: "noAction"
}

export const getDaysInfo = createAsyncThunk(
    "timesheet/getDaysInfo",
    async (data: any, thunkAPI) => {
        try {
            const allStates: any = thunkAPI.getState();
            const state = allStates.timeSheet;
            const response = await AppDependencies.WorkEntryService.GetEmployeeDays(state.week.weekStart, 
                state.week.weekEnd, state.selectedDate);
                return {daysInfo: response};
        } catch (error) {
            return thunkAPI.rejectWithValue({error: "failure"});
        }
    }
);

export const timeSheetSlice = createSlice({
    name: "timesheet",
    initialState,
    reducers: {
        setPrevWeek: (state, action) => {
            state.week = {
                weekStart: subDays(state.week.weekStart, 7),
                weekEnd: subDays(state.week.weekEnd, 7)
            };
            state.selectedDate = state.week.weekStart;
        },
        setNextWeek: (state, action) => {
            state.week = {
                weekStart: addDays(state.week.weekStart, 7),
                weekEnd: addDays(state.week.weekEnd, 7)
            };
            state.selectedDate = state.week.weekStart;
        },
        setCurrentWeek: (state, action) => {
            state.week = {
                weekStart: action.payload.week.weekStart,
                weekEnd: action.payload.week.weekEnd
            };
        },
        setTimesheetSelectedDate: (state, action) => {
            state.selectedDate = action.payload.selectedDate;
        },
        setTimesheetCurrentEmployeeId: (state, action) => {
            state.currentEmployeeId = action.payload.currentEmployeeId;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isFulfilled(getDaysInfo), (state, action) => {
            state.daysInfoLoadingState = "fulfilled";
            state.daysInfo = action.payload.daysInfo;
        });
        builder.addMatcher(isPending(getDaysInfo), (state, action) => {
            state.daysInfoLoadingState = "pending";
        });
        builder.addMatcher(isRejected(getDaysInfo), (state, action) => {
            state.daysInfoLoadingState = "rejected";
        });
    }
})

export const timeSheet = timeSheetSlice.reducer;

export const {
    setTimesheetSelectedDate,
    setTimesheetCurrentEmployeeId,
    setPrevWeek,
    setNextWeek,
    setCurrentWeek
} = timeSheetSlice.actions;

export const selectTimesheetSelectedDate = (state: RootState) => state.timeSheet.selectedDate;
export const selectTimesheetDaysInfo = (state: RootState) => state.timeSheet.daysInfo;
export const selectTimesheetWeek = (state: RootState): IWeek => state.timeSheet.week;
export const selectCurrentEmployeeId = (state: RootState) => state.timeSheet.currentEmployeeId;
export const selectDaysInfoLoadingState = (state: RootState) => state.timeSheet.daysInfoLoadingState;
export const selectDaysInfo = (state: RootState) => state.timeSheet.daysInfo;
