import {axiosApi} from "../configs/apiConfig";
import {AppDependencies} from "./appDependencies";
import {toast} from "react-hot-toast";
import {ICreateConfigurationDto} from "../dtos/reportFilter/ICreateConfigurationDto";
import { IConfigurationDto } from "../dtos/reportFilter/IConfigurationDto";

export class ReportConfigurationService {
    serviceUrl: string;

    constructor() {
        this.serviceUrl = "/ReportConfiguration";
    }

    async GetAll(): Promise<IConfigurationDto[]> {
        const url = this.serviceUrl;
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Create(item: ICreateConfigurationDto) {
        try {
            const response = await AppDependencies.Axios.post(this.serviceUrl, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async Update(item: any, newDescription: string) {
        try {
            const response = await AppDependencies.Axios.put(`${this.serviceUrl}/update/${newDescription}`, item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async MakeDefault(id: string) {
        try {
            const response = await AppDependencies.Axios.put(`${this.serviceUrl}/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Default status changed successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Change failure!");
        }
        return false;
    }

    async Delete(id: string) {
        try {
            const response = await AppDependencies.Axios.delete(`${this.serviceUrl}/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }

    async getAllByCustomer(customerId: string) {
        const url = `${this.serviceUrl}/by-customer/${customerId}`;
        const response = await axiosApi.get(url);
        return response.data;
    }
}
