import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../redux/store";
import {defaultLocale} from "../configs/appConfig";

export interface LocalizationState {
    locale: string;
}

const initialState: LocalizationState = {
    locale: defaultLocale,
}

export const localizationSlice = createSlice({
    name: 'localization',
    initialState,
    reducers: {
        setLocale: (state, action: any) => {
            state.locale = action.payload;
        },
    },
});

export const {setLocale} = localizationSlice.actions;
export const selectLocale = (state: RootState) => state.localization.locale;

export const localization = localizationSlice.reducer;
