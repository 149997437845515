import {AppDependencies} from "./appDependencies";
import {toast} from "react-hot-toast";
import {IObjectHistoryDto} from "../dtos/history/IObjectHistoryDto";

export class HistoryService {
    async GetHistory(projectId: string): Promise<IObjectHistoryDto[]> {
        try {
            const url = `/ObjectHistories/${projectId}`;
            const response = await AppDependencies.Axios.get(url);
            if (response.status === 200) {
                return response.data;
            }
        } catch (ex) {
            let message = "";
            if (ex instanceof Error) {
                message = ex.message;
            } else {
                message = String(ex);
            }
            console.log(ex);
            toast.error("Unknown API error!");
        }
        return [];
    }
}