import currenciesJson from "../assets/json/currencies.json";
import {ICurrencyDto} from "../dtos/currencies/ICurrencyDto";
import {defaultCurrency} from "../configs/appConfig";

export class CurrencyService {
    async GetAll(): Promise<ICurrencyDto[]> {
        const currencies: ICurrencyDto[] = [];
        Object.entries(currenciesJson).map((item: any) => {
            if (item[0] === "GEL" || item[0] === "USD" || item[0] === "EUR") {
                currencies.push({
                    id: item[0],
                    description: item[0]
                });
            }
        });
        return currencies;
    }

    async GetCurrencyRate(currency?: string): Promise<number> {
        let rate = 0;
        if (!currency) {
            rate = 0;
        } else if (currency === defaultCurrency) {
            rate = 1;
        } else {
            rate = 2;
        }
        return rate;
    }
}