// You can customize the template with the help of this file

//Template config options
import {ToastPosition} from "react-hot-toast";

interface IAppSettings {
    appName: string;
    appLogoImage: any;
}

interface IMenuSettings {
    isHidden: boolean,
    isCollapsed: boolean,
}

interface INavBarSettings {
    type: string,
    backgroundColor: string,
}

interface IFooterSettings {
    type: string,
}

interface ILayoutSettings {
    isRTL: boolean,
    skin: string,
    routerTransition: string,
    type: string,
    contentWidth: string,
    menu: IMenuSettings,
    navbar: INavBarSettings,
    footer: IFooterSettings,
    customizer:boolean,
    scrollTop: boolean,
    toastPosition: ToastPosition
}

interface IThemeConfig {
    app: IAppSettings,
    layout: ILayoutSettings
}

const themeConfig: IThemeConfig = {
    app: {
        appName: 'TimeSheet',
        appLogoImage: require('@src/assets/images/logo/logo.svg').default
    },
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: 'horizontal', // vertical, horizontal
        contentWidth: 'boxed', // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: 'white' // BS color options [primary, success, etc]
        },
        footer: {
            type: 'hidden' // static, sticky, hidden
        },
        customizer: false,
        scrollTop: true, // Enable scroll to top button
        toastPosition: 'top-left'
    }
}

export default themeConfig
