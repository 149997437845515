import {IProjectDraftDto} from "../dtos/projectDrafts/IProjectDraftDto";
import {AppDependencies} from "./appDependencies";
import {toast} from "react-hot-toast";
import {ICreateProjectDraftDto} from "../dtos/projectDrafts/ICreateProjectDraftDto";
import {IProjectDto} from "../dtos/projects/IProjectDto";

export class ProjectDraftService {
    async GetAll(searchText?: string): Promise<IProjectDraftDto[]> {
        let url = `/ProjectDrafts`;
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Create(item: ICreateProjectDraftDto): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.post("/ProjectDrafts", item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status !== 200) {
                throw `requestError. status code ${response.status}`;
            }

            toast.success("Created successfully!");
            return true;

        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async GetOne(projectId: string): Promise<IProjectDraftDto> {
        const url = `/ProjectDrafts/one/${projectId}`;
        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async Update(item: IProjectDraftDto): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.put("/ProjectDrafts", item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.delete(`/ProjectDrafts/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }
}
