import {IWorkEntryDto} from "../dtos/workEntries/IWorkEntryDto";
import {axiosApi} from "../configs/apiConfig";
import {ITrackingResponseDto} from "../dtos/tracking/ITrackingResponseDto";
import {AppDependencies} from "./appDependencies";

export class TrackingService {
    async start(item: IWorkEntryDto): Promise<ITrackingResponseDto | null> {
        const response = await AppDependencies.Axios.post(`tracking/start/${item.id}`)
        if (response.status === 200) {
            return response.data;
        }

        console.log("Tracking error");
        console.log(response);
        
        return null;
    }

    async stop(item: IWorkEntryDto): Promise<ITrackingResponseDto | null> {
        const response = await AppDependencies.Axios.post(`tracking/stop/${item.id}`)
        if (response.status === 200) {
            return response.data;
        }

        console.log("Tracking error");
        console.log(response);

        return null;
    }

    async check(item: IWorkEntryDto): Promise<ITrackingResponseDto | null> {
        const response = await AppDependencies.Axios.post(`tracking/check/${item.id}`)
        if (response.status === 200) {
            return response.data;
        }

        console.log("Tracking error");
        console.log(response);

        return null;
    }
}