import { AppDependencies } from "./appDependencies";
import { TimesheetReportRequestDto } from "../dtos/reports/TimesheetReportRequestDto";
import { toast } from "react-hot-toast";
import { TimesheetReportResponseDto } from "../dtos/reports/TimesheetReportResponseDto";
import { WorkEntryResponseDto } from "../dtos/reports/WorkEntryResponseDto";

export class ReportService {
  async Timesheet(
    startDate: Date,
    endDate: Date,
    columns: any,
    actualTimeStatus: boolean
  ): Promise<TimesheetReportResponseDto> {
    try {
      const dto: TimesheetReportRequestDto = {
        startDate,
        endDate,
        columns,
        actualTimeStatus,
      };
      const response = await AppDependencies.Axios.post(
        `/reports/Timesheet`,
        dto
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      console.log(response);
      return response.data;
    } catch (e) {
      console.log(e);
      toast("Error while loading timesheet report");
      //throw e;
    }
    return {
      items: [],
      administrationTime: [],
    };
  }
  async EmployeeTimesheet(
    startDate: Date,
    endDate: Date
  ): Promise<TimesheetReportResponseDto> {
    try {
      const dto: TimesheetReportRequestDto = {
        startDate,
        endDate,
      };
      const response = await AppDependencies.Axios.post(
        `/reports/EmployeesTimesheet`,
        dto
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      console.log(response);
      return response.data;
    } catch (e) {
      console.log(e);
      toast("Error while loading timesheet report");
      //throw e;
    }
    return {
      items: [],
      administrationTime: [],
    };
  }

  async GanttReport(): Promise<any> {
    const url = `/reports/Gantt`;

    try {
      const response = await AppDependencies.Axios.get(url);
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      console.log(response);
      return response.data;
    } catch (e) {
      console.log(e);
      toast("Error while loading timesheet report");
      //throw e;
    }

    return [];
  }

  async GanttReportEmployee(): Promise<any> {
    const url = `/reports/Gantt/employee`;

    try {
      const response = await AppDependencies.Axios.get(url);
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      console.log(response);
      return response.data;
    } catch (e) {
      console.log(e);
      toast("Error while loading timesheet report");
      //throw e;
    }

    return [];
  }

  async WorkEntry(
    startDate: Date,
    endDate: Date
  ): Promise<WorkEntryResponseDto> {
    try {
      const dto: TimesheetReportRequestDto = {
        startDate,
        endDate,
      };
      const response = await AppDependencies.Axios.post(
        `/reports/WorkEntry`,
        dto
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      console.log(response);
      return response.data;
    } catch (e) {
      console.log(e);
      toast("Error while loading Work entry report");
      //throw e;
    }
    return {
      items: [],
    };
  }
}
