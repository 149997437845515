import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface IUIState {
    skin: string | null;
}

const initialState: IUIState = {
    skin: null
}

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        getSkin: (state, action) => {
            state.skin = localStorage.getItem("skin");
        }
    }
});

export const uiReducer = uiSlice.reducer;

export const selectCurrentSkin = (state: RootState) => localStorage.getItem("skin");