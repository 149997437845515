import {createSlice} from "@reduxjs/toolkit";
import {IDepartmentDto} from "../../dtos/departments/IDepartmentDto";
import {RootState} from "../store";
import {timeSheetSlice} from "./timeSheetSlice";
import {IProjectDto} from "../../dtos/projects/IProjectDto";

interface IHomeState {
    currentDepartmentId?: string;
    currentProjectId?: string;
    currentDepartment?: IDepartmentDto;
    currentProject?: IProjectDto;
    status?: Date;
}

const initialState: IHomeState = {}

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setCurrentDepartment: (state, action) => {
            state.currentDepartment = action.payload.currentDepartment;
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload.currentProject;
        },
        updateNotificationBar: (state, action) => {
            state.status = action.payload;
          },
    }
});

export const homeReducer = homeSlice.reducer;


export const {
    setCurrentDepartment,
    setCurrentProject,
    updateNotificationBar
} = homeSlice.actions;

export const selectCurrentDepartment = (state: RootState) => state.homeReducer.currentDepartment;
export const selectCurrentProject = (state: RootState) => state.homeReducer.currentProject;
export const selectCurrentNotificationBar = (state: RootState) => state.homeReducer.status;