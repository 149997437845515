import { axiosApi, currentApi } from "../configs/apiConfig";
import { IProjectTypeDto } from "../dtos/projectTypes/IProjectTypeDto";
import { AppDependencies } from "./appDependencies";
import { toast } from "react-hot-toast";
import { ICreateProjectTypeDto } from "../dtos/projectTypes/ICreateProjectTypeDto";

export class ProjectTypeService {
  serviceUrl: string;

  constructor() {
    this.serviceUrl = "/ProjectTypes";
  }

  async GetAll(searchText?: string): Promise<IProjectTypeDto[]> {
    let url = this.serviceUrl;
    if (searchText && searchText !== "") {
      url += `/${searchText}`;
    }

    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async Create(item: ICreateProjectTypeDto) {
    try {
      const response = await AppDependencies.Axios.post(
        this.serviceUrl,
        item,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Created successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Create failure!");
    }
    return false;
  }

  async Update(item: IProjectTypeDto) {
    try {
      const response = await AppDependencies.Axios.put(
        this.serviceUrl,
        item,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Updated successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Update failure!");
    }
    return false;
  }

  async Delete(id: string) {
    try {
      const response = await AppDependencies.Axios.delete(
        `${this.serviceUrl}/${id}`
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Deleted successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Delete failure!");
    }
    return false;
  }

  async getAllByCustomer(customerId: string) {
    const url = `${this.serviceUrl}/by-customer/${customerId}`;
    const response = await axiosApi.get(url);
    return response.data;
  }

  async getAllByActiveStatus(departmentId: string) {
    const url = `${this.serviceUrl}/by-active-status?projectId=${departmentId}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }
}
