import {axiosApi, currentApi} from "../configs/apiConfig";
import {toast} from "react-hot-toast";
import { ICreateSalaryDto } from "../dtos/salary/ICreateSalaryDto";

export class SalaryService {
    async GetOne(userId: string): Promise<ICreateSalaryDto[]> {
        const response = await axiosApi.get(`/salary/${userId}`);
        return response.data;
    }

    async Create(item: ICreateSalaryDto[]) {
        try {
            const response = await axiosApi.post(`/salary`, item, {});
            if (process.env.MODE === "debug") {
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else if (response.status === 401) {
               
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }
            toast.error("Create failure!");
        }
        return false;
    }

}
