import axios from "axios";

export const apiConfig = {
    reports: {
        Gantt: {
            queryKey: "report_gantt",
            url: "/reports/Gantt",
        },
    }
}

const devApi = "http://localhost:4001/api";
const prodApi = "https://timesheet-api.soft-unity.com/api";
const testApi = "https://timesheet-test-api.soft-unity.com/api";
const myTestApi = "https://ecovis-timesheet.everridge.cloud/api";
const nodeApi_prod = "https://timesheet.ecoit.ge/api";
const nodeApi = process.env.REACT_APP_LOCAL_API;
export const currentApi = nodeApi_prod;

const reportLocalAPI = "http://localhost:5139/api/reports/Gantt";
export const currentReportAPI = reportLocalAPI;

export const axiosApi = axios.create({
    baseURL: currentApi, 
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = (localStorage.getItem("token")) || null
    if (token) {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);