import axios from "axios";
import { axiosApi, currentApi } from "../configs/apiConfig";
import { toast } from "react-hot-toast";
import { ICreateWorkTypeDto } from "../dtos/workTypes/ICreateWorkTypeDto";
import { IWorkTypeDto } from "../dtos/workTypes/IWorkTypeDto";
import { AppDependencies } from "./appDependencies";

export class WorkTypeService {
  async GetAll(searchText?: string): Promise<IWorkTypeDto[]> {
    let url = `/workTypes`;
    if (searchText && searchText !== "") {
      url += `/${searchText}`;
    }

    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async Create(item: ICreateWorkTypeDto) {
    try {
      const response = await AppDependencies.Axios.post(`/workTypes`, item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Created successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Create failure!");
    }
    return false;
  }

  async Update(item: IWorkTypeDto) {
    try {
      const response = await AppDependencies.Axios.put(`/workTypes`, item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Updated successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Update failure!");
    }
    return false;
  }

  async Delete(id: string) {
    try {
      const response = await AppDependencies.Axios.delete(`/workTypes/${id}`);
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Deleted successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Delete failure!");
    }
    return false;
  }

  async getAllByActiveStatus() {
    const url = "workTypes/by-active-status";
    const response = await axiosApi.get(url);

    return response.data;
  }
}
