import axios from "axios";
import { axiosApi, currentApi } from "../configs/apiConfig";
import { format } from "date-fns";
import { IGetDaysResponseDto } from "../dtos/workEntries/IGetDaysResponseDto";
import { IGetDaysRequestDto } from "../dtos/workEntries/IGetDaysRequestDto";
import { IUpdateWorkEntryFunctionalDto } from "../dtos/workEntries/IUpdateWorkEntryFunctionalDto";
import { ICreateWorkEntryDto } from "../dtos/workEntries/ICreateWorkEntryDto";
import { TimeSheetControlPeriodType } from "../enums/TimeSheetControlPeriodType";
import { IUpdateWorkEntryDto } from "../dtos/workEntries/IUpdateWorkEntryDto";
import { IDeleteWorkEntryDto } from "../dtos/workEntries/IDeleteWorkEntryDto";
import { toast } from "react-hot-toast";

export class WorkEntryService {
  async GetEmployeeDays(
    start: Date,
    end: Date,
    selectedDate: Date,
  ): Promise<IGetDaysResponseDto> {
    const body: IGetDaysRequestDto = {
      weekStart: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      weekEnd: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      selectedDate: format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    };
    // const response = await axios.post(`${currentApi}/workEntry/days`, body);
    const response = await axiosApi.post("/workEntry/days", body);
    return response.data;
  }

  async Create(dto: ICreateWorkEntryDto) {
    const response = await axiosApi.post(`/workEntry`, dto);
    return response.data;
  }

  async Update(dto: IUpdateWorkEntryDto) {
    try {
      const response = await axiosApi.put(`/workEntry`, dto);
      return response.data;
    } catch (e) {
      toast.error("Update failure!");
    }

    return null;
  }

  async Delete(id: string) {
    const response = await axiosApi.delete(`/workEntry/${id}`);
    if (response.status === 200) return true;
    return false;
  }

  async DeleteBatch(dto: IDeleteWorkEntryDto) {
    const response = await axiosApi.post(`/workEntry/delete-batch`, dto);
    if (response.status === 200) return true;
    return false;
  }

  async WorkEntryUpdateFunctional(start: Date, end: Date) {
    const body: IUpdateWorkEntryFunctionalDto = {
      startDate: start,
      endDate: end,
    };
    const response = await axiosApi.post("/workEntry/update-functional", body);
    return response;
  }

  async WorkEntryNotification() {
    const response = await axiosApi.get(
      `/workEntry/notification`
    );
    return response.data;
  }
}
