import axios from "axios";
import {currentApi} from "../configs/apiConfig";
import {ITokenDto} from "../dtos/accounts/ITokenDto";
import {toast} from "react-hot-toast";
import {AppDependencies} from "./appDependencies";
import {IChangePasswordDto} from "../dtos/accounts/IChangePasswordDto";

export class AccountService {
    async login(userName: string, password: string): Promise<ITokenDto | undefined> {
        const body = {
            userName,
            password
        };
        try {
            const response = await AppDependencies.Axios.post(`${currentApi}/Accounts/login`, body, {});
            return response.data;
        } catch (error) {
            console.log(error)
        }
        
        // const response = await AppDependencies.Axios.post(`${currentApi}/Accounts/login`, body, {});
        // console.log(response);
        // if (response.status === 200) {
        //     return response.data;
        // }
        return undefined;
    }

    async currentUser() {
        const response = await AppDependencies.Axios.get(
          `${currentApi}/Accounts/currentUser`
        );
        const responseDto = response.data;
        return responseDto;
    }

    async getUserPhoto(userName: string): Promise<string | undefined> {
        try {
            const url = `${currentApi}/accounts/photo/${userName}`;
            const response = await AppDependencies.Axios.get(url);
            return response.data;
        } catch (e) {
            return undefined;
        }
    }

    async ChangePassword(data: IChangePasswordDto): Promise<boolean> {
        const body = {...data};
        const response = await AppDependencies.Axios.post(`${currentApi}/Accounts/change-password`, body, {});
        if (response.status !== 200) {
            toast.error("API error!");
            return false;
        }
        if (response.status === 200) {
            if (response.data.success === true) return true;
            toast.error(response.data.errorMessage);
        }
        return false;
    }
}
