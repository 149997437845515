import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {AppDependencies} from "../services/appDependencies";
import {toast} from "react-hot-toast";
import {parseISO} from "date-fns";

export const ResponseInterceptor = () => {
    const navigate = useNavigate()
    const interceptorId = useRef<number | null>(null);

    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;
    //const isoDateFormat = "/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/";

    function isIsoDateString(value: any): boolean {
        return value && typeof value === "string" && isoDateFormat.test(value);
    }

    const handleDates = (body: any) => {
        if (body === null || body === undefined || typeof body !== "object") {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (isIsoDateString(value)) body[key] = parseISO(value);
            else if (typeof value === "object") handleDates(value);
        }
    }

    useEffect(() => {
        interceptorId.current = AppDependencies.Axios.interceptors.response.use(
            (originalResponse) => {
                handleDates(originalResponse);
                return originalResponse;
            },
            (error) => {
                if (!error.response) {
                    toast.error("Network error, please check the internet connection!");
                    return;
                }
                switch (error.response.status) {
                    case 400:
                        toast.error(error.response.data);
                        break;
                    case 401:
                        navigate("/account/login");
                        const statusCode = error.response.data.code;
                        switch (statusCode) {
                            case 0:
                                toast.error("Invalid login or password!");
                                break;
                            case 1:
                                toast.error("User is locked!");
                                break;
                            default:
                                toast.error("Unknown error!");
                                break;
                        }
                        break;
                    case 404:
                        toast.error("API Error - Not found!")
                        break;
                }
            });

        return () => {
            const current = Number(interceptorId.current);
            AppDependencies.Axios.interceptors.response.eject(current);
        };
    }, []);

    return null;
};