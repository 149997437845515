import axios from "axios";
import {axiosApi, currentApi} from "../configs/apiConfig";
import {IDepartmentDto} from "../dtos/departments/IDepartmentDto";
import {toast} from "react-hot-toast";
import {ICreateDepartmentDto} from "../dtos/departments/ICreateDepartmentDto";
import {AppDependencies} from "./appDependencies";

export class DepartmentService {
    async GetAll(searchText?: string) {
        let url = "/departments";
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async GetList(searchText?: string) {
        let url = "/departments/list";
        if (searchText && searchText !== "") {
            url += `/${searchText}`;
        }

        const response = await AppDependencies.Axios.get(url);
        return response.data;
    }

    async GetOne(id: string) {
        const response = await AppDependencies.Axios.get(`/departments/one/${id}`);
        return response.data;
    }

    async Create(item: ICreateDepartmentDto) {
        try {
            const dto = {...item};
            if (dto.headEmployeeId === "") dto.headEmployeeId = undefined;
            console.log(dto);
            const response = await AppDependencies.Axios.post("/departments", dto, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Created successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Create failure!");
        }
        return false;
    }

    async Update(item: IDepartmentDto) {
        try {
            const response = await AppDependencies.Axios.put("/departments", item, {});
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Updated successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Update failure!");
        }
        return false;
    }

    async Delete(id: string) {
        try {
            const response = await AppDependencies.Axios.delete(`/departments/${id}`);
            if (process.env.MODE === "debug") {
                console.log(response);
            }

            if (response.status === 200) {
                toast.success("Deleted successfully!");
                return true;
            } else {
                throw `requestError. status code ${response.status}`;
            }
        } catch (e) {
            if (process.env.MODE === "debug") {
                console.log(e);
            }

            toast.error("Delete failure!");
        }
        return false;
    }
}
