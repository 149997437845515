import { axiosApi } from "../configs/apiConfig";
import { toast } from "react-hot-toast";
import { ICreateProjectDto } from "../dtos/projects/ICreateProjectDto";
import { IProjectDto } from "../dtos/projects/IProjectDto";
import { IDataService } from "./data-service.interface";
import { AppDependencies } from "./appDependencies";
import { IProjectDashboardItemDto } from "../dtos/projects/IProjectDashboardItemDto";
import { IApiResponseDto } from "../dtos/IApiResponseDto";
import { IProjectDraftDto } from "../dtos/projectDrafts/IProjectDraftDto";

interface SelectOptions {
  id: string;
  name: string;
}

export class ProjectService implements IDataService<IProjectDto, ICreateProjectDto> {
  async GetAll(searchText?: string): Promise<IProjectDto[]> {
    let url = `/projects`;
    if (searchText && searchText !== "") {
      url += `/${searchText}`;
    }

    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async GetByUser(
    projectTypeId?: string,
    customerId?: string
  ): Promise<IProjectDto[]> {
    try {
      const url = `/projects/by-user/${projectTypeId}?customerId=${customerId}`;
      const body = {
        TypeId: projectTypeId,
      };
      const response = await AppDependencies.Axios.get(url);
      if (response.status === 200) {
        const responseData: IApiResponseDto<IProjectDto> = response.data;
        if (responseData.success) {
          return responseData.data ?? [];
        } else {
          if (responseData.errorMessage) toast.error(responseData.errorMessage);
        }
      }
    } catch (ex) {
      let message = "";
      if (ex instanceof Error) {
        message = ex.message;
      } else {
        message = String(ex);
      }
      console.log(ex);
      toast.error("Unknown API error!");
    }
    return [];
  }

  async Create(item: ICreateProjectDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.post("/projects", item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Created successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Create failure!");
    }
    return false;
  }

  async Delete(id: string): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.delete(`/projects/${id}`);
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Deleted successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Delete failure!");
    }
    return false;
  }

  async Update(item: IProjectDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.put("/projects", item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Updated successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Update failure!");
    }
    return false;
  }

  async GetByDepartment(
    departmentId: string,
    includeEmployees: boolean = false,
    item?: SelectOptions[]
  ): Promise<IProjectDashboardItemDto[]> {
    const includeEmployeesUrlPart = "?includeEmployees=true";
    const url = `/projects/by-department/${departmentId}${
      includeEmployees ? includeEmployeesUrlPart : ""
    }`;
    const response = await AppDependencies.Axios.post(url, item);

    return response.data;
  }

  async GetOne(projectId: string): Promise<IProjectDto> {
    const url = `/projects/one/${projectId}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async GetDetails(projectId: string) {
    const url = `/projects/details/${projectId}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async CreateFromDraft(
    projectName: string,
    start: Date,
    finish: Date,
    projectDraft: IProjectDraftDto
  ): Promise<boolean> {
    try {
      const dto = {
        projectName,
        start,
        finish,
        projectDraftId: projectDraft.id,
      };

      const response = await AppDependencies.Axios.post(
        "/projects/from-draft",
        dto,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Project created successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Project create failure!");
    }
    return false;
  }

  async CompleteProject(projectId: string) {
    try {
      const response = await AppDependencies.Axios.post(
        `/projects/complete/${projectId}`,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Complete successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Complete failure!");
    }
  }

  async CloseProject(projectId: string) {
    try {
      const response = await AppDependencies.Axios.post(
        `/projects/close/${projectId}`,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Cancaled successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Cancaled failure!");
    }
  }

  async GetPendingDays(projectId: string) {
    const url = `/projects/pendingDays/${projectId}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async GetFilteredProjectsByDepartment(
    filterObject: any,
    currentDepartment?: string | null
  ): Promise<IProjectDashboardItemDto[] | null> {
    const response = await axiosApi.get(`/projects/byDepartment/filter`, {
      params: {
        currentDepartmentId: currentDepartment,
        customer: filterObject.customer,
        projectType: filterObject.projectType,
        description: filterObject.description,
        manager: filterObject.manager,
      },
    });
    return response.data;
  }

  async CreateCopyProject(item: ICreateProjectDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.post(
        "/projects/copy",
        item,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Created successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Create failure!");
    }
    return false;
  }
}
